import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { AuthContext } from "contexts/AuthContext";
import SettingsContextProvider from "contexts/SettingsContext";
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import WorkOrders from "pages/orders/WorkOrders";
import WorkOrderDetails from "pages/order-details/WorkOrderDetails";
import Graphics from "pages/graphics/Graphics";
import Productions from "pages/items/Productions";
import Components from "pages/items/Components";
import InventoryTransactions from "pages/inventory-transactions/InventoryTransactions";
import FinishedGoodsInventory from "pages/finished-goods-inventory/FinishedGoodsInventory";
import Users from "pages/users/Users";
import Logs from "pages/logs/Logs";
import { HelmetProvider } from "react-helmet-async";
import shippedOrders from "pages/shipped-orders/ShippedOrders";
import PickingList from "pages/picking-list/PickingList";
import Consolidate from "pages/consolidations/Consolidation";
// import Brother from "pages/queue-print/Brother";
// import Epson from "pages/queue-print/Epson";
import QueuePrint from "pages/queue-print/QueuePrint";
import Shipment from "pages/shipment/Shipment";
import Sessions from "pages/sessions/Sessions";
import PackingList from "pages/packing-list/PackingList";
import Settings from "pages/Settings";
import DefectProcess from "pages/defect/DefectProcess";
import Bins from "pages/Bins";
import DefectTransactions from "pages/defect/DefectTransactions";
import BrowsingOrderItem from "pages/orders/BrowsingOrderItem";
import OrderItemsActivity from "pages/order-items-activity/OrderItemsActivity";
// import Contact from "pages/Contact";
import { FaEnvelope } from "react-icons/fa";
import PrintedFilmsInventory from "pages/printed-films-inventory/PrintedFilmsInventory";
import { QueuePrintGraphic } from "pages/queue-print/QueuePrintGraphic";
import { useIdleTimer } from "react-idle-timer";
import SyncOrders from "pages/orders/SyncOrders";
const queryClient = new QueryClient();

const RequireLoggedInUser = ({ children }) => {
  const { user: currentUser, logout } = React.useContext(AuthContext);
  const location = useLocation();

  const onIdle = () => {
    if (!location.pathname.includes("login")) logout();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 180,
    onIdle,
  });

  return currentUser ? children : <Login />;
};

const Main = () => {
  return (
    <main>
      <QueryClientProvider client={queryClient}>
        <SettingsContextProvider>
          {/* <AuthContextProvider> */}
          <RequireLoggedInUser>
            <HelmetProvider>
              <Switch>
                <Route exact component={Dashboard} path="/" />
                <Route exact component={Login} path="/login" />
                <Route exact component={WorkOrders} path="/work-orders" />
                <Route exact component={shippedOrders} path="/shipped-orders" />
                <Route exact component={SyncOrders} path="/sync-orders" />
                <Route
                  exact
                  component={BrowsingOrderItem}
                  path="/browsing-order-item"
                />
                <Route
                  exact
                  component={OrderItemsActivity}
                  path="/order-items-activity"
                />
                <Route
                  exact
                  component={WorkOrderDetails}
                  path="/workorder-details/:orderId"
                />
                <Route exact component={PickingList} path="/picking-list" />
                <Route exact component={Consolidate} path="/consolidate" />
                <Route exact component={PackingList} path="/packing-list" />
                <Route exact component={QueuePrint} path="/queue-print" />
                <Route
                  exact
                  component={QueuePrintGraphic}
                  path="/queue-print-graphic"
                />
                {/* <Route exact component={Brother} path="/queue-print-brother" /> */}
                <Route exact component={Shipment} path="/shipment" />
                <Route exact component={Graphics} path="/graphics" />
                <Route exact component={Productions} path="/productions" />
                <Route exact component={Components} path="/components" />
                <Route
                  exact
                  component={InventoryTransactions}
                  path="/inventory-transactions"
                />
                <Route
                  exact
                  component={FinishedGoodsInventory}
                  path="/finished-goods-inventory-status"
                />
                <Route
                  exact
                  component={PrintedFilmsInventory}
                  path="/printed-films-inventory"
                />
                <Route exact component={DefectProcess} path="/defect" />
                <Route
                  exact
                  component={DefectTransactions}
                  path="/defect-transactions"
                />
                <Route exact component={Bins} path="/bins" />
                <Route exact component={Users} path="/users" />
                <Route exact component={Logs} path="/logs" />
                <Route exact component={Settings} path="/settings" />
                {/* <Route exact component={Contact} path="/contact" /> */}
                <Route exact component={Sessions} path="/sessions" />
              </Switch>
            </HelmetProvider>
          </RequireLoggedInUser>
          {/* </AuthContextProvider> */}
        </SettingsContextProvider>
      </QueryClientProvider>
      <footer>
        <span className="contact-link">
          <FaEnvelope className="mr-10" />
          <small>tech-support@printingify.com</small>
        </span>
        <small>© 2021 Printingify {process.env.REACT_APP_VERSION}</small>
      </footer>
    </main>
  );
};

export default Main;
