import _ from "lodash";
import { serializeError } from "serialize-error";

export const errorHandler = (error) => {
  console.error("* errorHandler init");
  console.error("- error: ", error);
  const parsedErr = serializeError(error);
  // console.log("- parsedErr from errorHandler", parsedErr);
  console.error(
    "- error?.response?.data?.message",
    error?.response?.data?.message
  );

  if (error?.response?.data?.message) {
    return error.response.data.message;
  } else if (_.has(parsedErr, "message")) {
    let retval = parsedErr.message;
    console.log("- retval::parsedErr.message: ", retval);
    if (_.has(parsedErr, "config")) {
      if (_.has(parsedErr.config, "url")) {
        retval += `, ${parsedErr.config.url}`;
      }
    }
    return retval;
  } else {
    return "Unknown error occured";
  }
};
