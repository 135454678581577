import React, { memo } from "react";
import moment from "moment";
import _ from "lodash";
import HTMLReactParser from "html-react-parser";
import QRCode from "react-qr-code";

export const PackingSlipTemplate = memo(
  ({ shipFrom, ssOrder, localOrder, orderItems, settings }) => {
    let name, street1, city, state, postalCode, country;
    if (shipFrom) {
      name = shipFrom?.name;
      street1 = shipFrom?.street1;
      city = shipFrom?.city;
      state = shipFrom?.state;
      postalCode = shipFrom?.postalCode;
      country = shipFrom?.country;
    }
    let orderNumber,
      orderDate,
      shipTo,
      gift,
      giftMessage,
      customerNotes,
      advancedOptions;

    if (localOrder) {
      orderNumber = localOrder?.orderNumber;
      orderDate = localOrder?.orderDate;
      shipTo = localOrder?.shipTo;
    }

    if (ssOrder) {
      orderNumber = ssOrder?.orderNumber;
      orderDate = ssOrder?.orderDate;
      shipTo = ssOrder?.shipTo;
      gift = ssOrder?.gift;
      giftMessage = ssOrder?.giftMessage;
      customerNotes = ssOrder?.customerNotes;
      advancedOptions = ssOrder?.advancedOptions;
    }

    let customField1, customField2, customField3;

    if (advancedOptions) {
      customField1 = advancedOptions.customField1;
      customField2 = advancedOptions.customField2;
      customField3 = advancedOptions.customField3;
    }

    // console.log("- ssOrder", ssOrder);
    // console.log("- localOrder", localOrder);
    // console.log("[PackingSlipTemplate] orderItems: ", orderItems);
    // console.log("- giftMessage", giftMessage)
    // console.log("-customerNotes", customerNotes.slice(ssOrder?.customerNotes.indexOf('To:')*1))
    // console.log("- customField1", customField1)
    // console.log("- customField2", customField2
    // console.log("- customField3", customField3)
    // console.log("- settings", settings)
    return (
      <div id="print-section" className="hidden">
        <div
          className="print-section-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <h3>Packing Slip</h3>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {shipFrom && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{name}</span>
                <span>{street1}</span>
                <span>{`${city}, ${state} ${postalCode} ${country}`}</span>
              </div>
            )}
          </div>
        </div>
        <div>
          {orderItems && Boolean(orderItems.length) && (
            <div
              style={{
                pageBreakAfter: "always",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ paddingRight: "10px", fontWeight: "400" }}>
                    Ship To
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{shipTo?.name ? shipTo.name : ""}</span>
                    {shipTo?.street1 && (
                      <>
                        <span>{shipTo.street1}</span>
                        <span>{`${shipTo.city}, ${shipTo.state} ${shipTo.postalCode} ${shipTo.country}`}</span>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>Order #: {orderNumber}</span>
                  <span>Date: {moment(orderDate).format("MM/DD/YYYY")}</span>
                  <span>
                    Ship Date: {new Date().toLocaleDateString("en-US")}
                  </span>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th style={{ minWidth: "100px" }}>SKU</th>
                    <th>Description</th>
                    <th>Ordered</th>
                    <th>Shipped</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item.sku}</td>
                        <td>{item?._item?.description}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-center">{item.quantity}</td>
                        {/* <td className="text-center">{item.checkedOutQty}</td> */}
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        border: "#666 .5px solid",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        border: "#666 .5px solid",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      {_.sumBy(orderItems, "quantity")}
                    </td>
                    <td
                      style={{
                        border: "#666 .5px solid",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      {_.sumBy(orderItems, "quantity")}
                    </td>
                  </tr>
                </tbody>
              </table>
              {gift && giftMessage && (
                <div>
                  <h3>Message:</h3>
                  <h3>{HTMLReactParser(giftMessage)}</h3>
                </div>
              )}
              {customerNotes && customerNotes.includes("To:") && (
                <div>
                  <h3>Customer Notes:</h3>
                  <h3>
                    {HTMLReactParser(
                      customerNotes.slice(customerNotes.indexOf("To:") * 1)
                    )}
                  </h3>
                </div>
              )}
              {settings?.useCustomfieldsMsg &&
                customField1 &&
                customField2 &&
                customField3 && (
                  <div>
                    <h3>Message:</h3>
                    <h3>To: {customField1}</h3>
                    <h3>From: {customField2}</h3>
                    <h3>{customField3}</h3>
                  </div>
                )}
              {settings?.useCustomerGuide && (
                <div className="customer-guide-wrapper">
                  <span className="block">
                    For all questions, returns, and exchanges please use this QR
                    code to access our FAQ page.
                  </span>
                  <div className="text-center">
                    <QRCode
                      value="https://www.homefieldapparel.com/a/faq"
                      size={100}
                      level="Q"
                    />
                  </div>
                  <span className="block">
                    Thank you for your order, and if you need to reach out
                    directly you can do so at info@HomefieldApparel.com!
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
