// import Axios from "axios";
import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export async function createShippingLabel(order, orderNumber, isTestLabel) {
  return new Promise((resolve, reject) => {
    const url = `ship-station/create-label-for-order`;
    // console.info("== remove comment order.testLabel true on Production ===");
    order["testLabel"] = isTestLabel;

    // console.log("createShippingLabel request order", order);

    axios
      .post(url, { ...order }, { params: { orderNumber } })
      .then((res) => {
        // console.log("createShippingLabel res", res);
        resolve(res.data);
      })
      .catch((error) => {
        console.log("create label error", error);
        reject(error);
      });
  });
}

export async function getAllShipStationOrders({ orderStatus }) {
  return new Promise((resolve, reject) => {
    const url = `ship-station/all-orders`;
    axios
      .get(url, { params: { orderStatus } })
      .then((res) => {
        // console.log("getAllShipStationOrders res", res.data);
        resolve(res.data);
      })
      .catch((error) => {
        console.log("getAllShipStationOrders error", error);
        reject(error);
      });
  });
}

export async function getShipStationOrder(orderId) {
  return new Promise((resolve, reject) => {
    const url = `ship-station/orders/${orderId}`;
    axios
      .get(url)
      .then((res) => {
        // console.log("getShipStationOrder res", res.data);
        resolve(res.data);
      })
      .catch((error) => {
        console.log("getShipStationOrder error", error);
        reject(error);
      });
  });
}

export async function getWarehouse(warehouseId) {
  console.log("* getWarehouse init");
  return new Promise((resolve, reject) => {
    const url = `ship-station/warehouse/${warehouseId}`;
    axios
      .get(url)
      .then((res) => {
        // console.log("getWarehouse res", res.data);
        resolve(res.data);
      })
      .catch((error) => {
        console.log("getWarehouse error", error);
        reject(error);
      });
  });
}

export const fetchListShipments = (params) => {
  // console.log("* fetchListShipments init");
  // console.log("- params: ", params);
  return new Promise((resolve, reject) => {
    const url = `ship-station/list-shipments`;
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("getOrderRef error", error);
        const retval = errorHandler(error);
        reject(retval);
      });
  });
};

export const removeTag = (props) => {
  console.log("* removeTag init");
  console.log("- props: ", props);
  return new Promise((resolve, reject) => {
    const url = "ship-station/removetag";
    axios
      .post(url, props)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("removeTag error", error);
        const retval = errorHandler(error);
        reject(retval);
      });
  });
};
